import React from "react";
import { EnvelopeIcon } from "@heroicons/react/24/solid";

import GitHubIcon from "../assets/icons/GitHubIcon";
import LinkedInIcon from "../assets/icons/LinkedInIcon";

const Logos = () => {
  return (
    <div className="mt-8">
      <div>
        <div className="flex items-center group w-fit">
          <GitHubIcon className="fill-zinc-600 dark:fill-zinc-400 h-6 mr-4 group-hover:fill-[#4ab2f1]" />
          <span className="text-zinc-600 dark:text-zinc-400 group-hover:text-[#4ab2f1]">
            <a href="https://github.com/tdkent">Follow on GitHub</a>
          </span>
        </div>
        <div className="mt-6 flex items-center group w-fit">
          <LinkedInIcon className="fill-zinc-600 dark:fill-zinc-400 h-6 mr-4 group-hover:fill-[#4ab2f1]" />
          <span className="text-zinc-600 dark:text-zinc-400 group-hover:text-[#4ab2f1]">
            <a href="https://www.linkedin.com/in/timkent1/">
              Follow on LinkedIn
            </a>
          </span>
        </div>
        <div className="mt-6 flex items-center group w-fit">
          <EnvelopeIcon className="fill-zinc-600 dark:fill-zinc-400 h-6 mr-4 group-hover:fill-[#4ab2f1]" />
          <span className="text-zinc-600 dark:text-zinc-400 group-hover:text-[#4ab2f1]">
            <a href="mailto:timkentdev@gmail.com">timkentdev@gmail.com</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Logos;
