import React from "react"

const Footer = () => {
  return (
    <footer className="pt-4 pb-12 w-full md:w-[90%] xl:w-[1024px] mx-auto border-t border-[#ececee] dark:border-zinc-600 md:border-t-0 md:bg-white dark:bg-zinc-900 md:border-x">
      <p className="text-sm text-zinc-400 text-center">&copy; 2023 Tim Kent</p>
    </footer>
  )
}

export default Footer
