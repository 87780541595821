import React from "react"

import Project from "../components/Project"

const Projects = () => {
  return (
    <section id="projects" className="mt-16 pb-12">
      <h2 className="px-4 md:px-12 xl:px-20 text-4xl font-semibold text-zinc-400 dark:text-zinc-100">
        My projects
      </h2>
      <div className="mx-2 md:mx-10 xl:mx-20">
        <Project />
      </div>
    </section>
  )
}

export default Projects
