import React from "react"
import { LinkIcon } from "@heroicons/react/24/outline"

import data from "../data/project-data"

const Project = () => {
  return (
    <>
      {data.map((prj) => {
        return (
          <a
            href={prj.siteUrl}
            key={prj.name}
            target="_blank"
            rel="noreferrer"
            className="group">
            <div className="mt-8 rounded-md md:rounded-xl transition duration-300 group-hover:bg-zinc-100 dark:group-hover:bg-zinc-800 px-2 md:pl-4 md:pr-12 xl:pl-4 xl:pr-6 xl:mr-32 py-4 md:py-6">
              <div className="rounded-full border border-[#ececee] dark:border-zinc-600 w-[70px] h-[70px] p-[6px] mb-4">
                <img
                  src={prj.img}
                  alt={prj.name}
                  className="h-[56px] rounded-full mx-auto"
                />
              </div>
              <h3 className="font-semibold text-[#4ab2f1]">{prj.name}</h3>
              <p className="mt-4">{prj.description}</p>
              <p className="mt-4 text-sm text-zinc-800 dark:text-zinc-400">
                <span className="font-medium">Tech Stack:</span>{" "}
                {prj.stack.join(", ")}
              </p>
              <div className="flex items-center mt-4 group-hover:text-sky-500">
                <LinkIcon className="h-4 mr-2 group-hover:stroke-sky-500" />
                {prj.siteUrl.slice(8, -1)}
              </div>
            </div>
          </a>
        )
      })}
    </>
  )
}

export default Project
