import React from "react";

const Portrait = () => {
  return (
    <div className="mt-10 px-4 md:flex md:justify-center">
      <img
        src="https://res.cloudinary.com/do28aglgy/image/upload/v1686088956/timdkent/portrait_my0d4n.jpg"
        alt="Portrait"
        className="rounded-full h-[96px] border border-[#ececee] dark:border-zinc-600"
      />
    </div>
  );
};

export default Portrait;
