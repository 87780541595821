const data = [
  {
    name: "Gold Country Model Horse Show",
    description:
      "Dynamic, fully responsive event info and registration website built with Nuxt and Tailwind.",
    siteUrl: "https://gcmhs.netlify.app/",
    stack: ["JavaScript", "TypeScript", "Vue.js", "Nuxt", "Tailwind CSS"],
    img: "https://res.cloudinary.com/do28aglgy/image/upload/v1726435859/timdkent/gmchs_lw8w8e.jpg",
  },
  {
    name: "Insects of Papua New Guinea",
    description:
      "Educational website showcasing a collection of images of over 1000 insect species documented in Papua New Guinea.",
    siteUrl: "https://www.pnginsects.com/",
    stack: [
      "JavaScript",
      "TypeScript",
      "Next.js",
      "React",
      "Zustand",
      "Tailwind CSS",
      "Node.js",
      "Express.js",
    ],
    img: "https://res.cloudinary.com/do28aglgy/image/upload/v1700942052/timdkent/insects-of-png_grewhe.jpg",
  },
  {
    name: "ListMaker",
    description:
      "Track goals and stay organized with ListMaker, a free-to-use shopping and to-do list-making application. ListMaker is in continuing development, with new features including additional list types planned.",
    siteUrl: "https://mylistmaker.netlify.app/",
    stack: [
      "JavaScript",
      "TypeScript",
      "React",
      "React Query",
      "Node.js",
      "Express.js",
      "PostgreSQL",
      "Tailwind CSS",
    ],
    img: "https://res.cloudinary.com/do28aglgy/image/upload/v1686088133/timdkent/listmaker_hshu3m.jpg",
  },
  {
    name: "Historic Landmarks of San Francisco",
    description:
      "Content-driven website featuring articles, guides, and maps about the official California landmarks in San Francisco.",
    siteUrl: "https://www.hlosf.com/",
    stack: ["JavaScript", "Next.js", "React", "Tailwind CSS"],
    img: "https://res.cloudinary.com/do28aglgy/image/upload/v1673386061/timdkent/historical-landmarks-of-san-francisco_cvgmhp.jpg",
  },
  {
    name: "SharePlaces",
    description:
      "Travel app featuring user base, image upload, and ability to create and edit geocoded Place posts.",
    siteUrl: "https://share-places-app.netlify.app/",
    stack: [
      "JavaScript",
      "React",
      "Node.js",
      "Express.js",
      "MongoDB",
      "Amazon S3",
    ],
    img: "https://res.cloudinary.com/do28aglgy/image/upload/v1673386061/timdkent/share-places_ecgmbt.jpg",
  },
  {
    name: "TBM Book Sellers",
    description:
      "Full-service e-commerce application with thousands of books for sale, created in a 4-week agile sprint with three other developers.",
    siteUrl: "https://tbmbooksellers.netlify.app/",
    stack: [
      "JavaScript",
      "React",
      "Node.js",
      "Express.js",
      "PostgreSQL",
      "Stripe",
    ],
    img: "https://res.cloudinary.com/do28aglgy/image/upload/v1673386061/timdkent/tbm-book-sellers_atsmul.jpg",
  },
];

export default data;
