import React from "react"

const Nav = () => {
  return (
    <nav className="mx-2">
      <ul className="flex italic text-zinc-400 dark:text-zinc-100 font-handwriting text-xl">
        <li className="mr-8 md:mr-12 xl:mr-16 hover:text-[#4ab2f1] transition duration-300">
          <a href="#about">About</a>
        </li>
        <li className="hover:text-[#4ab2f1] transition duration-300">
          <a href="#projects">Projects</a>
        </li>
      </ul>
    </nav>
  )
}

export default Nav
