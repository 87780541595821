import React from "react";

import Logos from "../components/Logos";

const About = () => {
  return (
    <section
      id="about"
      className="mt-12 px-4 md:pl-12 md:pr-20 xl:pl-20 xl:pr-36"
    >
      <h2 className="text-4xl font-semibold text-zinc-400 dark:text-zinc-100">
        Full stack <span className="text-[#4ab2f1]">software engineer</span>
      </h2>
      <p className="mt-8">
        Hi, I'm a JavaScript developer building full stack applications with
        TypeScript, React, Vue.js Next.js, Nuxt, Node.js, SQL, MongoDB, and
        Tailwind CSS.
      </p>
      <Logos />
    </section>
  );
};

export default About;
